// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100vh;
    width: 100%;
    z-index: 1;
}

.spinner {
    border-color: rgba(6, 41, 61) transparent rgba(6, 41, 61) transparent;
    animation: spin-anim 1.2s linear infinite;
    border-radius: 50%;
    border: 8px solid;
    height: 64px;
    width: 64px;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Preloader/Preloader.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,WAAW;IACX,UAAU;AACd;;AAEA;IACI,qEAAqE;IACrE,yCAAyC;IACzC,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".loader-container {\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    height: 100vh;\n    width: 100%;\n    z-index: 1;\n}\n\n.spinner {\n    border-color: rgba(6, 41, 61) transparent rgba(6, 41, 61) transparent;\n    animation: spin-anim 1.2s linear infinite;\n    border-radius: 50%;\n    border: 8px solid;\n    height: 64px;\n    width: 64px;\n}\n\n@keyframes spin-anim {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
