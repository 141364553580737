// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button__block-button {
    box-shadow: 0px 0px 10px 0px rgba(111, 111, 111, 0.25);
    background-color: #4985FF;
    text-decoration: none;
    border-radius: 10px;
    cursor: pointer;
    flex-shrink: 0;
    width: 200px;
    height: 45px;
}

.button__block-button_text {
    margin: 13px auto 15px auto;
    font-family: 'Montserrat';
    line-height: normal;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFF;
}

@media (hover: hover) {
    .button__block-button:hover {
        transition: 1s;
        opacity: .6;
    }
}

@media screen and (max-width: 320px) {
    .button__block-button {
        margin: 0 auto 20px;
    }
}

@media screen and (max-width: 768px) {
    .button__block-button {
        margin: 0 auto 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Button/Button.css"],"names":[],"mappings":"AAAA;IACI,sDAAsD;IACtD,yBAAyB;IACzB,qBAAqB;IACrB,mBAAmB;IACnB,eAAe;IACf,cAAc;IACd,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,2BAA2B;IAC3B,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,WAAW;AACf;;AAEA;IACI;QACI,cAAc;QACd,WAAW;IACf;AACJ;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".button__block-button {\n    box-shadow: 0px 0px 10px 0px rgba(111, 111, 111, 0.25);\n    background-color: #4985FF;\n    text-decoration: none;\n    border-radius: 10px;\n    cursor: pointer;\n    flex-shrink: 0;\n    width: 200px;\n    height: 45px;\n}\n\n.button__block-button_text {\n    margin: 13px auto 15px auto;\n    font-family: 'Montserrat';\n    line-height: normal;\n    text-align: center;\n    font-style: normal;\n    font-weight: 500;\n    font-size: 16px;\n    color: #FFF;\n}\n\n@media (hover: hover) {\n    .button__block-button:hover {\n        transition: 1s;\n        opacity: .6;\n    }\n}\n\n@media screen and (max-width: 320px) {\n    .button__block-button {\n        margin: 0 auto 20px;\n    }\n}\n\n@media screen and (max-width: 768px) {\n    .button__block-button {\n        margin: 0 auto 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
