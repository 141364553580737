// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/back.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    padding-bottom: 130px;
    align-items: center;
    display: flex;
}

.team__title {
    font-family: 'Montserrat';
    line-height: 146.28%;
    margin: 100px auto 0;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    color: #fff;
}

.team__description {
    color: var(--grey, #999);
    font-family: 'Montserrat';
    line-height: 158.47%;
    margin: 50px auto 0;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    max-width: 730px;
    font-size: 16px;
    padding: 0 5px;
}

.team__staff {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px calc((100% - (255px * 3)) / 2);
    gap: 15px;
}

@media screen and (max-width: 768px) {
    .team__staff {
        justify-content: center;
    }
}

@media screen and (max-width: 320px) {
    .team__staff {
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Team/Team.css"],"names":[],"mappings":"AAAA;IACI,yDAA4C;IAC5C,4BAA4B;IAC5B,uBAAuB;IACvB,sBAAsB;IACtB,sBAAsB;IACtB,qBAAqB;IACrB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,oBAAoB;IACpB,oBAAoB;IACpB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,wBAAwB;IACxB,yBAAyB;IACzB,oBAAoB;IACpB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,4CAA4C;IAC5C,SAAS;AACb;;AAEA;IACI;QACI,uBAAuB;IAC3B;AACJ;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".team {\n    background-image: url(../../images/back.svg);\n    background-repeat: no-repeat;\n    justify-content: center;\n    flex-direction: column;\n    background-size: cover;\n    padding-bottom: 130px;\n    align-items: center;\n    display: flex;\n}\n\n.team__title {\n    font-family: 'Montserrat';\n    line-height: 146.28%;\n    margin: 100px auto 0;\n    text-align: center;\n    font-style: normal;\n    font-weight: 700;\n    font-size: 36px;\n    color: #fff;\n}\n\n.team__description {\n    color: var(--grey, #999);\n    font-family: 'Montserrat';\n    line-height: 158.47%;\n    margin: 50px auto 0;\n    text-align: center;\n    font-style: normal;\n    font-weight: 400;\n    max-width: 730px;\n    font-size: 16px;\n    padding: 0 5px;\n}\n\n.team__staff {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    padding: 40px calc((100% - (255px * 3)) / 2);\n    gap: 15px;\n}\n\n@media screen and (max-width: 768px) {\n    .team__staff {\n        justify-content: center;\n    }\n}\n\n@media screen and (max-width: 320px) {\n    .team__staff {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
