// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    font-family: 'OpenSans', 'Buyan', 'Montserrat';
    background-color: rgb(0, 0, 0, 1);
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    color: rgba(242, 244, 250);
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    scroll-behavior: smooth;
    letter-spacing: .015em;
    min-height: 100vh;
    min-width: 280px;
    margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/App/App.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;IAC9C,iCAAiC;IACjC,kCAAkC;IAClC,kCAAkC;IAClC,8BAA8B;IAC9B,0BAA0B;IAC1B,2BAA2B;IAC3B,0BAA0B;IAC1B,uBAAuB;IACvB,sBAAsB;IACtB,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[":root {\n    font-family: 'OpenSans', 'Buyan', 'Montserrat';\n    background-color: rgb(0, 0, 0, 1);\n    text-rendering: optimizeLegibility;\n    -moz-osx-font-smoothing: grayscale;\n    -webkit-text-size-adjust: 100%;\n    color: rgba(242, 244, 250);\n    -moz-text-size-adjust: 100%;\n    -ms-text-size-adjust: 100%;\n    scroll-behavior: smooth;\n    letter-spacing: .015em;\n    min-height: 100vh;\n    min-width: 280px;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
