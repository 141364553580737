// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.staff-component {
    display: flex;
    flex-direction: column;
}

.staff-component__block {
    display: flex;
}

.staff-component__image {
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
    width: 191.25px;
    transition: 1s;
    height: 255px;
}

.staff-component__image:hover {
    transform: scale(1.2);
}

.staff-component__image:last-child {
    margin: 0;
}

.staff__title {
    font-family: 'Montserrat';
    line-height: normal;
    margin: 30px auto 0;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
}

.staff__post {
    color: var(--grey, #cbc8c8);
    font-family: 'Montserrat';
    line-height: 158.47%;
    margin: 10px auto 0;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

@media screen and (max-width: 768px) {
    .staff-component__image {
        margin-right: 0;
    }

    .staff-component {
        margin: 0 40px;
    }

}`, "",{"version":3,"sources":["webpack://./src/Components/StaffComponent/StaffComponents.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,2BAA2B;IAC3B,yBAAyB;IACzB,oBAAoB;IACpB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,cAAc;IAClB;;AAEJ","sourcesContent":[".staff-component {\n    display: flex;\n    flex-direction: column;\n}\n\n.staff-component__block {\n    display: flex;\n}\n\n.staff-component__image {\n    border-radius: 10px;\n    object-fit: cover;\n    cursor: pointer;\n    width: 191.25px;\n    transition: 1s;\n    height: 255px;\n}\n\n.staff-component__image:hover {\n    transform: scale(1.2);\n}\n\n.staff-component__image:last-child {\n    margin: 0;\n}\n\n.staff__title {\n    font-family: 'Montserrat';\n    line-height: normal;\n    margin: 30px auto 0;\n    text-align: center;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 18px;\n    color: #fff;\n}\n\n.staff__post {\n    color: var(--grey, #cbc8c8);\n    font-family: 'Montserrat';\n    line-height: 158.47%;\n    margin: 10px auto 0;\n    text-align: center;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 12px;\n}\n\n@media screen and (max-width: 768px) {\n    .staff-component__image {\n        margin-right: 0;\n    }\n\n    .staff-component {\n        margin: 0 40px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
