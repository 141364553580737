// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup {
    background-color: rgba(0, 0, 0, .4);
    justify-content: flex-end;
    pointer-events: none;
    align-items: center;
    position: fixed;
    transition: .5s;
    display: flex;
    z-index: 9999;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    right: 0;
    top: 0;
}

.popup.active {
    pointer-events: all;
    opacity: 1;
}

.popup__content {
    background-color: rgba(6, 41, 61, .9);
    justify-content: center;
    flex-direction: column;
    transform: scale(.5);
    border-radius: 20px;
    transition: .4s all;
    display: flex;
    padding: 20px;
    height: 95vh;
    /* width: 50vw; */
}

.popup__content.active {
    transform: scale(1);
}

.popup__services_close-icon {
    position: absolute;
    max-height: 30px;
    cursor: pointer;
    border: none;
    right: 10px;
    padding: 0;
    top: 10px;
}

/* @media screen and (max-width: 280px) {

    .popup__content {
        height: 80vh;
        width: 80%;
    }
} */`, "",{"version":3,"sources":["webpack://./src/Components/Popup/Popup.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,yBAAyB;IACzB,oBAAoB;IACpB,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,aAAa;IACb,aAAa;IACb,aAAa;IACb,YAAY;IACZ,UAAU;IACV,QAAQ;IACR,MAAM;AACV;;AAEA;IACI,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,qCAAqC;IACrC,uBAAuB;IACvB,sBAAsB;IACtB,oBAAoB;IACpB,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,WAAW;IACX,UAAU;IACV,SAAS;AACb;;AAEA;;;;;;GAMG","sourcesContent":[".popup {\n    background-color: rgba(0, 0, 0, .4);\n    justify-content: flex-end;\n    pointer-events: none;\n    align-items: center;\n    position: fixed;\n    transition: .5s;\n    display: flex;\n    z-index: 9999;\n    height: 100vh;\n    width: 100vw;\n    opacity: 0;\n    right: 0;\n    top: 0;\n}\n\n.popup.active {\n    pointer-events: all;\n    opacity: 1;\n}\n\n.popup__content {\n    background-color: rgba(6, 41, 61, .9);\n    justify-content: center;\n    flex-direction: column;\n    transform: scale(.5);\n    border-radius: 20px;\n    transition: .4s all;\n    display: flex;\n    padding: 20px;\n    height: 95vh;\n    /* width: 50vw; */\n}\n\n.popup__content.active {\n    transform: scale(1);\n}\n\n.popup__services_close-icon {\n    position: absolute;\n    max-height: 30px;\n    cursor: pointer;\n    border: none;\n    right: 10px;\n    padding: 0;\n    top: 10px;\n}\n\n/* @media screen and (max-width: 280px) {\n\n    .popup__content {\n        height: 80vh;\n        width: 80%;\n    }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
