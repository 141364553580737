// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-component-link {
    color: var(--grey, #999);
    font-family: 'Montserrat';
    list-style-type: none;
    line-height: 158.47%;
    font-style: normal;
    font-weight: 400;
    margin-top: 30px;
    font-size: 14px;
    cursor: pointer;
}

.list-component-link__text {
    color: var(--grey, #2c2a2a);
    text-decoration: none;
    margin: 0;
}

.list-component-link__link:first-child {
    margin-top: 0;
}

.list-component-link__text:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/Components/ListComponentLink/ListComponentLink.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,yBAAyB;IACzB,qBAAqB;IACrB,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,2BAA2B;IAC3B,qBAAqB;IACrB,SAAS;AACb;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".list-component-link {\n    color: var(--grey, #999);\n    font-family: 'Montserrat';\n    list-style-type: none;\n    line-height: 158.47%;\n    font-style: normal;\n    font-weight: 400;\n    margin-top: 30px;\n    font-size: 14px;\n    cursor: pointer;\n}\n\n.list-component-link__text {\n    color: var(--grey, #2c2a2a);\n    text-decoration: none;\n    margin: 0;\n}\n\n.list-component-link__link:first-child {\n    margin-top: 0;\n}\n\n.list-component-link__text:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
